<template>
  <div
    class="FaqClientWindow mobile"
    ref="faqClientTag"
    :style="{ height: windowHeight }"
  >
    <div
      class="head"
      :class="withInitialForm && 'initialForm'"
      v-if="showDefaultHeader"
    >
      <div class="section">
        <a class="logo" v-if="showHeaderIcon" @click.prevent="customClear()">
          <img
            :src="headerIconSrc(headerIconFilename)"
            :style="spHeaderLogoHeight ? { height: spHeaderLogoHeight } : {}"
          />
        </a>
        <div class="title" v-if="showHeaderTitle">
          <div class="text">
            <router-link to="/">{{ mainTitle }}</router-link>
          </div>
        </div>
      </div>
      <div class="section right" v-if="withInitialForm && userId">
        <div class="user_info">
          <div class="text">{{ userInfo }}</div>
        </div>
        <a class="logout" @click.prevent="removeUserId()">
          <font-awesome-icon icon="sign-out-alt" />
          <span>{{ logOutText }}</span>
        </a>
      </div>
    </div>
    <div class="content">
      <div class="search" ref="taggedInput" id="sai-tag-search-input">
        <TaggedInput
          :selectedTags="selectedTags.values()"
          :searchText="searchText"
          :inputEmpty="inputEmpty"
          :filteredItems="filteredItems"
          @onTagRemoved="removeSelectedTag"
          @onSearchTextChanged="setSearchText"
          @setShow="setTagSearchShow"
        />
      </div>
    </div>
    <div class="main">
      <div
        class="recommend"
        v-show="$store.state.tagSearch.show"
        :style="{
          overflow: inputEmpty ? 'scroll' : 'auto',
        }"
      >
        <div ref="tagList">
          <PopularTagList
            v-if="inputEmpty"
            :tags="initialTags"
            :tagTitle="tagPopularTitle"
            :numberOfKeywordsDisplayed="numberOfInitKeywordsDisplayed"
            @setShow="setTagSearchShow"
          />
          <TagList
            v-if="!inputEmpty"
            :candidateTags="candidateTags"
            :tagListTop="tagListTop"
            :inputEmpty="inputEmpty"
            :searchText="searchText"
            @onSelectTag="addSelectedTag"
            @setShow="setTagSearchShow"
          />
        </div>
        <template v-if="(spWithFrequentFaqArea && inputEmpty) || !inputEmpty">
          <div class="autocomplete_header">
            <div class="title">{{ faqListTitle }}</div>
            <div class="search_result" v-if="!inputEmpty">
              <span class="count">
                {{ filteredItems.length }}
              </span>
              <span class="text">{{ candidatesCountSubText }}</span>
            </div>
          </div>
        </template>
        <template v-if="inputEmpty">
          <div class="custom-list" v-if="spWithFrequentFaqArea">
            <Frequent @setTagSearchShow="setTagSearchShow" />
          </div>
          <template v-if="spWithImageTagsArea">
            <TagCategory
              v-for="imgTag in imageTags"
              :key="imgTag.index"
              :title="imgTag.title"
              :tags="imgTag.tags"
              @tagifyText="tagifyTextExact"
              @setShow="setTagSearchShow"
            />
          </template>
        </template>
        <div class="autocomplete custom-list" v-if="!inputEmpty">
          <Autocomplete
            v-if="filteredItems.length > 0"
            :filteredItems="filteredItems"
            @selected="selectItem"
            @setTagSearchShow="setTagSearchShow"
          />
          <div v-if="filteredItems.length === 0" class="no_candidates_faq">
            <div class="sai-caption" v-html="noCandidateFaqMessage"></div>
          </div>
        </div>
      </div>
      <ColumnNavigation />
    </div>
    <template v-if="spSciseedLink">
      <div class="bottom_icon">
        <a href="https://saichat.jp/saisearch/" target="_blank" rel="noopener">
          <img src="@/resource/powered_by_sciseed.png" />
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';

@Component({})
export default class FaqClientWindow extends mixins(FaqClientTagMixin) {
  scrollTopElement = this.$store.state.constObj.SP_SCROLL_TOP_ELEMENT;
  scrollFaqElement = '#sai-scenario';
  autoScrollElement = '#sai-tag-search-input';
  iphoneTopElement = this.$store.state.constObj.SP_SCROLL_TOP_ELEMENT;

  windowHeight = '100vh';

  get faqListTitle() {
    return this.inputEmpty
      ? this.$store.state.constObj.FREQUENT_FAQ_TITLE
      : this.$store.state.constObj.CANDIDATE_FAQ_TITLE;
  }
  autoScroll() {
    if (this.$store.state.user.isIphone) {
      return;
    }
    if (!this.$store.state.tagSearch.show) {
      this.scrollTo(this.autoScrollElement, {
        container: this.scrollContainer,
        x: false,
        duration: 500,
      });
    }
  }
  setHeight() {
    this.windowHeight = `${window.innerHeight}px`;
  }
  created() {
    setTimeout(() => {
      this.setHeight();
    }, 0);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/faqClientWindow';
</style>
